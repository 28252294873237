<template>
  <div class="fees-and-tariffs-page-wrapper bb">
    <div class="fees-and-tariffs-page">
      <div class="fees-holder">
        <div class="fee bb">
          <div class="title">Free banking period</div>
          <div class="text-bold">{{ bankDetails.accountFeatures.freeBankingPeriod }}</div>
          <div class="meta">{{ bankDetails.accountFeatures.freeBankingPeriod | pluralize('Month', false) }}</div>
        </div>
        <div class="fee bb">
          <div class="title">Account fee</div>
          <div class="text-bold">{{ bankDetails.accountFeatures.accountFee.amount }}</div>
          <div class="meta">{{ bankDetails.accountFeatures.accountFee.description }}</div>
        </div>
        <div class="fee bb">
          <div class="title">Average days to open an account</div>
          <div class="text-bold">{{ bankDetails.accountFeatures.averageDaysToOpenAnAccountFee }}</div>
        </div>
        <div class="fee bb">
          <div class="title">Average days to receive a debit card</div>
          <div class="text-bold">{{ bankDetails.accountFeatures.averageDaysToReceiveADebitCard }}</div>
        </div>
        <div class="fee bb">
          <div class="title">Average days to setup internet banking</div>
          <div class="text-bold">{{ bankDetails.accountFeatures.averageDaysToSetupInternetBanking }}</div>
        </div>
      </div>
      <div class="savings-holder table-responsive">
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Annual savings</th>
            </tr>
            <tr>
              <th></th>
              <th
                :class="{
                  'text-primary': bankDetails.savingFees.totalSavings > 0,
                  'text-danger': bankDetails.savingFees.totalSavings < 0
                }"
              >
                {{ bankDetails.savingFees.totalSavings | numberSeparator | currency }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(saving, savingIndex) in bankDetails.savingFees.savings" :key="savingIndex">
              <td>{{ saving.name | splitUpperCase }}</td>
              <td
                :class="{
                  'text-primary': saving.value > 0,
                  'text-danger': saving.value < 0
                }"
              >
                {{ saving.value | numberSeparator | currency }}
              </td>
            </tr>
          </tbody>
        </table>
        <!-- <strong class="table-subtitle cta-text">
          Ready to continue? Close this website and return to Business Banking Switch where you can submit your interest in one or more of these offers
        </strong>-->
      </div>
    </div>
    <p class="disclaimer">
        Swoop has prepared your personalised comparison summary, using transactions from your business current account over the past 12 months to predict the banking fees you are likely to incur with each of the banks we compare, if you continue to make the same number and type of transactions. Swoop does not compare credit or lending products including Overdrafts, Credit Cards or Loan accounts.
        The comparison is based on your historic banking usage and the published tariff data of the banks compared. The results provide an indication of potential savings. Customers must consider their current and future banking needs and seek independent advice where necessary. Fees may change and business current accounts may be removed from the comparison at any time.
    </p>
  </div>
</template>

<script>
export default {
  name: 'FeesAndTariffs',
  props: {
    bankDetails: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../assets/styles/swoop/variables';

.fees-and-tariffs-page-wrapper {
  padding: 55px 0 160px 0;
  .meta {
    color: $color-main;
    background: none;
    border: none;
    font-size: 12px;
    line-height: 16px;
  }
}

.fees-and-tariffs-page {
  display: flex;
  @media only screen and (max-width: $breakpoint-sm-max) {
    flex-wrap: wrap;
  }
}
.fees-holder {
  flex-shrink: 0;
  border: 1px solid $default-border-color;
  border-radius: 8px;
  margin-right: 40px;
  box-shadow: $default-box-shadow;
  @media only screen and (max-width: $breakpoint-sm-max) {
    padding: 0;
    text-align: center;
    flex-basis: 100%;
    margin-bottom: 30px;
  }
  .charge,
  .fee {
    text-align: center;
    font-size: 28px;
    padding: 35px 32px;
  }
  .bb {
    &:last-child {
      border: none;
    }
  }
  .title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: var(--color-primary-500);
  }
  .text-bold {
    font-size: 24px;
    line-height: 36px;
    color: var(--color-primary-500);
  }
  .text-primary {
    font-size: 32px;
    font-weight: bold;
    line-height: 39px;
    color: $color-primary;
  }
}
.savings-holder {
  flex-grow: 1;
  @media only screen and (max-width: $breakpoint-sm-max) {
    padding: 0;
  }
  table {
    width: 100%;
    @media only screen and (max-width: $breakpoint-sm-max) {
    }
    tr {
      &:first-child {
        th {
          font-size: 14px;
          font-weight: 500;
          line-height: 16px;
          color: var(--color-primary-300);
          text-align: right;
          padding-bottom: 5px;
        }
        td {
          border-top: 1px solid $default-border-color;
        }
      }
      &:last-child {
        th {
          padding-top: 0;
          padding-bottom: 9px;
          line-height: 39px;
          text-align: right;
          &:not(:first-child) {
            font-size: 14px;
            line-height: 16px;
            color: var(--color-primary-500);
            &.text-primary {
              color: $color-primary;
            }
            &.text-danger {
              color: var(--color-error-500);
            }
          }
        }
      }
      th,
      td {
        padding: 16px 15px;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        &:first-child {
          text-align: left;
          padding-left: 12px;
        }
        &:last-child {
          padding-right: 34px;
        }
      }
      th {
        background-color: var(--color-tertiary-50);
      }
      td {
        border-bottom: 1px solid $default-border-color;
        color: var(--color-primary-500);
        font-size: 16px;
        font-weight: 500;
        text-align: right;
        &:first-child {
          font-weight: normal;
          color: $color-main;
          text-align: left;
        }
        &.text-primary {
          color: $color-primary;
        }
        &.text-danger {
          color: var(--color-error-500);
        }
      }
    }
  }
}
</style>
